import {FC} from "react";
import {BoxDecoration} from "../box_decoration";

type StackParam = {
  width?: string,
  height?: string,
  decoration?: BoxDecoration
}
const Stack: FC<StackParam> = (
  {
    width,
    height,
    decoration,
    children
  }) => {
  
  return <div style={{
    width: width ?? "undefined",
    height: height ?? "undefined",
    position: "relative",
    backgroundColor: decoration?.color ?? "undefined",
    borderTopLeftRadius: decoration?.borderRadius?.topLeft ?? "undefined",
    borderTopRightRadius: decoration?.borderRadius?.topRight ?? "undefined",
    borderBottomLeftRadius: decoration?.borderRadius?.bottomLeft ?? "undefined",
    borderBottomRightRadius: decoration?.borderRadius?.bottomRight ?? "undefined",
    border: decoration?.stroke?.width ? (decoration?.stroke?.width + " solid " + decoration?.stroke?.color) : "undefined",
    borderColor: decoration?.stroke?.color ?? "undefined"
  }}>
    {children}
  </div>
}
export default Stack