import {FC} from "react";
import {Property} from "csstype";

type ColumnParam = {
  width?: string,
  height?: string,
  alignItems?: Property.AlignItems,
  justifyContent?: Property.JustifyContent,
  backgroundColor?: string
}
const Column: FC<ColumnParam> = (
  {
    width,
    height,
    backgroundColor,
    alignItems,
    justifyContent,
    children
  }) => {
  return (
    <div style={{
      width: (width ?? "undefined"),
      height: (height ?? "undefined"),
      display: "flex",
      flexDirection: "column",
      justifyContent: justifyContent ?? "center",
      alignItems: alignItems ?? "flex-start",
      backgroundColor: backgroundColor ?? "undefined",
    }}>
      {children}{/*获取子view*/}
    </div>)
}
export default Column

