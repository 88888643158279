import React from "react";
import './home_page.css';
import Row from '../../widget/common/linear/row'
import Column from '../../widget/common/linear/column'
import {Button, Image} from "antd";
import homeImg from '../../img/home_img.webp'

const {useHistory} = require('react-router-dom');

const Home: React.FC = () => {
  let history = useHistory();//全局的总页面，切换整个页面
  const styles = {
    bg: {
      maxWidth: "1920px",
      margin: "0 auto",
    },
  }
  return (<div style={styles.bg}>
    <Row justifyContent={"left"} height={"100%"}>
      <Column>
        <div className={"TitleLabel"}>快递批量查询</div>
        <div className={"ContentLabel"}>支持所有快递公司批量查询快递动态</div>
        <div className={"ContentLabel"}>韵达、中通、极兔、申通、圆通、邮政、百世、顺丰、京东等各大快递都支持</div>
        <div className={"ContentLabel"}>签收、在途、无物流、异常件，分门别类一目了然，可导出Excel筛选进一步分析数据</div>
        <div className={"Btn"}>
          <Row>
            <Button className={"ButtonItem"} onClick={() => {
              // @ts-ignore
             // _czc && _czc.push(["_trackEvent", "立即使用", "立即使用", "立即使用", "立即使用", "立即使用"])
              window.open("https://www.wenjuan.com/s/UZBZJvfzpV/")
            }}>立即使用</Button>
            <Button className={"ButtonItem"} onClick={() => {
              history.replace("/course")
            }}>查看教程</Button>
            <Button className={"ButtonItem"} onClick={() => {
              history.replace("/price")
            }}>购买套餐</Button>
          </Row>
        </div>
      </Column>
      <Image className={"HomeImg"} src={homeImg} preview={false}/>
    </Row>
  </div>)
}
export default Home