import React from "react";
import Row from "../../widget/common/linear/row";
import Column from "../../widget/common/linear/column";
import Container from "../../widget/container";
import {EdgeInsets} from "../../widget/common/edgeInsets";
import {Button} from "antd";
import Stack from "../../widget/common/stack/stack";
import priceImg from '../../img/price_img.webp'
import Position from "../../widget/common/stack/position";
import './price_page.css'

const PricePage: React.FC = () => {
    const styles = {
        bg: {
            maxWidth: "1920px",
            margin: "0 auto",
        },
        desc: {
            fontSize: "20px",
            color: "#c2c2c2",
            marginLeft: "170px",
            marginTop: "16px"
        },
        price: {
            color: "#ffffff",
            fontSize: "30px",
            fontWeight: 600
        },
        price_desc: {
            color: "#ffffff",
            fontSize: "16px",
            width: "172px",
            marginBottom: "24px",
            marginTop: "10px"
        },
        btn: {
            color: "#ffffff",
            fontSize: "16px",
            backgroundColor: "#04887D",
            borderColor: "#04887D",
            width: "80px",
            height: "34px"
        },
        card: {
            marginLeft: "148px",
            marginTop: "34px",
            zIndex: 2
        },
        img: {
            height: "86px",
        }
    }
    const data = [
        {price: "5/天", desc: "单天会员，开通后24小时内不限单数"},
        {price: "48/月", desc: "1月会员，开通后30天内不限次数"},
        {price: "118元/季度", desc: "季度会员，开通后3个月内不限单数"},
        {price: "386元/年", desc: "年度会员，开通后12个月内不限单数"},
    ]
    return (<div style={styles.bg}>
        <Stack height={"100%"} width={"100%"}>
            <Position top={"245px"} right={"385px"}>
                <img className={"PriceImg"} src={priceImg} alt={""}/>
            </Position>
            <Column height={"100%"}>
                <div className={"TitleLabel"}>价格套餐</div>
                <div style={styles.desc}>闪电快递云，快递批量查询更便宜</div>
                <div style={styles.desc}>支付灵活，支持多种套餐</div>
                <div style={styles.card}>
                    <Row>
                        {
                            data.map((value, index) => {
                                return <Container
                                    key={index}
                                    decoration={{stroke: {color: "#09665E", width: "2px"}}}
                                    margin={EdgeInsets.symmetric("20px")}
                                    padding={EdgeInsets.symmetric("22px", "46px")}>
                                    <Column justifyContent={"center"} alignItems={"center"}>
                                        <div style={styles.price}>{value.price}</div>
                                        <div style={styles.price_desc}>{value.desc}</div>
                                        <Button style={styles.btn} onClick={() => {
                                            // @ts-ignore
                                            // _czc && _czc.push(["_trackEvent", "开通", "开通", "开通", "开通", "开通"])
                                            window.open("https://www.wenjuan.com/s/UZBZJvfzpV/")
                                        }}>开通</Button>
                                    </Column>
                                </Container>
                            })
                        }
                    </Row>
                </div>
            </Column>
        </Stack>
    </div>)
}
export default PricePage