import React from "react";
import Row from "../../widget/common/linear/row";
import Column from "../../widget/common/linear/column";
import './about_page.css'

const AboutPage: React.FC = () => {
  const styles = {
    bg: {
      maxWidth: "1920px",
      margin: "0 auto",
    },
  }
  return (<div style={styles.bg}>
    <Row justifyContent={"left"} height={"100%"}>
      <Column>
        <div className={"TitleLabel"}>关于闪电快递云</div>
        <div
          className={"AboutDesc"}>闪电快递云，快递批量查询助手，是快递网点和电商平台客服批量跟单软件，快递单号查询、批量查询快递、快递物流记录信息分析平台，支持各类平台十几家主流快递单号快速批量查询、五万条物流单号的批量查询速度在几分钟，甚至在几十秒内全部完成。是查询快递、批量查询数万快递单号的神器。
        </div>
      </Column>
    </Row>
  </div>)
}
export default AboutPage