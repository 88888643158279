import {FC} from "react";
import Column from "../widget/common/linear/column";
import Row from "../widget/common/linear/row";
import './footer_view.css'
import SizeBox from "../widget/common/size_box";
import {Button} from "antd";

const {useHistory} = require('react-router-dom');
const FooterView: FC = () => {
  let history = useHistory();//全局的总页面，切换整个页面
  return (<div className={"FooterView"}>
    <Column height={"100%"} alignItems={"center"}>
      <Row justifyContent={"center"} width={"100%"}>
        <div className={"FooterTitle"}>友情链接：
        </div>
        <Button className={"Desc"} onClick={() => {
          history.replace("/")
        }}>快递批量查询
        </Button>
        <Button className={"Desc"} onClick={() => {
          history.replace("/")
        }}>批量查询快递
        </Button>
        <Button className={"Desc"} onClick={() => {
          history.replace("/")
        }}>查快递
        </Button>
        <Button className={"Desc"} onClick={() => {
          history.replace("/")
        }}>批量查快递
        </Button>
        <Button className={"Desc"} onClick={() => {
          window.open("https://www.zto.com/")
        }}>中通快递
        </Button>
        <Button className={"Desc"} onClick={() => {
          window.open("https://www.yto.net.cn/")
        }}>圆通快递
        </Button>
        <Button className={"Desc"} onClick={() => {
          window.open("https://www.jtexpress.com.cn/")
        }}>极兔快递
        </Button>
      </Row>
      <SizeBox height={"10px"}/>
      <Row justifyContent={"center"} width={"100%"}>
        <div className={"Record"}>Copyright © 2020-2021</div>
        <div className={"Record"}>版权所有</div>
        <a className={"Record"} href={"https://beian.miit.gov.cn"}>皖ICP备2021010520号</a>
        <div className={"Record"}>技术支持：批量查快递平台</div>
      </Row>
      <SizeBox height={"4px"}/>
      <div className={"Record"}>闪电快递云科技有限公司</div>
    </Column>
  </div>)
}
export default FooterView