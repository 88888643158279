import {FC} from "react";

type SizeBoxParam = {
  height?: string,
  width?: string,
}
/**
 * 做间距用
 * @param height
 * @param width
 * @constructor
 */
const SizeBox: FC<SizeBoxParam> = ({height, width}) => {
  return <div style={{width: width, height: height}}/>
}
export default SizeBox