export type EdgeInsetsGeometry = {
  left?: string,
  top?: string,
  right?: string,
  bottom?: string,
}

export const EdgeInsets = {
  only(edgeInsetsGeometry: EdgeInsetsGeometry): EdgeInsetsGeometry {
    return edgeInsetsGeometry
  },
  symmetric(horizontal?: string, vertical?: string): EdgeInsetsGeometry {
    return {
      left: horizontal,
      right: horizontal,
      top: vertical,
      bottom: vertical,
    }
  },
  horizontal(horizontal?: string): EdgeInsetsGeometry {
    return {
      left: horizontal,
      right: horizontal,
    }
  },
  vertical(vertical?: string): EdgeInsetsGeometry {
    return {
      top: vertical,
      bottom: vertical,
    }
  },
  all(value: string): EdgeInsetsGeometry {
    return {
      left: value,
      right: value,
      top: value,
      bottom: value,
    }
  }
}