import React from "react";
import './detail_page.css'
const DetailPage: React.FC = () => {


  return (<div className={"DetailLabel"}>
    闪电快递批量查询助手，是快递网点和电商平台客服批量跟单软件，快递单号查询、批量查询快递、快递物流记录信息分析平台，支持各类平台十几家主流快递单号快速批量查询、五万条物流单号的批量查询速度在几分钟，甚至在几十秒内全部完成
    目前网点版针对快递站点规则进行分析，可以提高快递网点客服跟单的效率 ，及时发现问题件。是快递网点降本增效非常实用的快递跟单、批量查询快递单号实用工具。
    软件是网页版设计，即开即用，网点导入三天前的出港单号可快速查询到催派件、滞留件等单号状态，第二天无需重新导入即可重查之前导入的单号 。对客服跟单工作效率有极高的辅助作用。
  </div>)
}
export default DetailPage