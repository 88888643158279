import './query_page.css'
import Column from "../../widget/common/linear/column";
import React from "react";
import 'antd/dist/antd.css';
import Expand from "../../widget/common/linear/expand";
import Row from "../../widget/common/linear/row";
import {Image} from "antd"; // 引入样式才生效

const QueryPage: React.FC = () => {
  let express = [
    [
      {img: "//app.baixingkankan.com/uPic/zhongtong.webp", label: "中通批量查快递"},
      {img: "//app.baixingkankan.com/uPic/yunda.webp", label: "韵达批量查快递"},
      {img: "//app.baixingkankan.com/uPic/shentong.webp", label: "申通批量查快递"},
      {img: "//app.baixingkankan.com/uPic/yuantong.webp", label: "圆通批量查快递"},
      {img: "//app.baixingkankan.com/uPic/baishi.webp", label: "百世批量查快递"},
    ],
    [
      {img: "//app.baixingkankan.com/uPic/jitu.webp", label: "极兔批量查快递"},
      {img: "//app.baixingkankan.com/uPic/youzheng.webp", label: "邮政批量查快递"},
      {img: "//app.baixingkankan.com/uPic/shunfeng.webp", label: "顺丰批量查快递"},
      {img: "//app.baixingkankan.com/uPic/jingdong.webp", label: "京东批量查快递"},
      {img: "//app.baixingkankan.com/uPic/qita.webp", label: "其他批量查快递"}
    ],
  ]
  return (<div className={"QueryPage"}>
    <Column justifyContent={"center"} height={"100%"} width={"100%"}>
      <div className={"TitleLabel"}>快递批量查询</div>
      {
        express.map((value, index) => {
          return <div className={"ExpressFa"} key={index + "out"}>
            <Row width={"100%"}>
              {
                value.map((value, index) => {
                  return <Expand flex={1} key={index + "innner"}>
                    <div className={"ExpressItemLayout"}>
                      <Column alignItems={"center"}>
                        <Image className={"Img"} src={value.img}/>
                        <div className={"Label"}>{value.label}</div>
                      </Column>
                    </div>
                  </Expand>
                })
              }
            </Row>
          </div>
        })
      }
    </Column>
  </div>)
}
export default QueryPage