import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css'; // 引入样式才生效
import Main from "./page/main/main_page";

const {BrowserRouter, Route, Switch} = require('react-router-dom');
//BrowserRouter全局只有1个
ReactDOM.render(
  <BrowserRouter key="root">
    <Switch>
      <Route component={Main}/>{/*第一个没有path的Route就是默认*/}
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);