import React from "react";
import "./news_page.css"
import Column from "../../widget/common/linear/column";

interface TextProps {
  text: string
}

const NewsDetailPage: React.FC<TextProps> = (props) => {
  // @ts-ignore
  let text = props.location.state.text.replaceAll("\n", "<br>")
  console.log(props)
  return (<Column alignItems={"center"} height={"100%"}>
    <div className={"news-detail-desc"}
         dangerouslySetInnerHTML={{
           __html: text
         }}>

    </div>
  </Column>)
}
export default NewsDetailPage