import React from "react";
import Row from "../../widget/common/linear/row";
import Column from "../../widget/common/linear/column";
import Container from "../../widget/container";
import {Button} from "antd";
import {Radius} from "../../widget/common/box_decoration";
import {EdgeInsets} from "../../widget/common/edgeInsets";
import './news_page.css'

const {useHistory} = require('react-router-dom');

const NewsPage: React.FC = () => {
  let history = useHistory();//全局的总页面，切换整个页面
  const styles = {
    bg: {
      maxWidth: "1920px",
      margin: "0 auto",
      height: "100%",
      overflow: "scroll"
    },
    img: {
      width: "60px",
    },
    img_right: {
      width: "222px",
      marginLeft: "160px"
    },
    title: {
      fontSize: "20px",
      color: "#ffffff"
    },
    btn: {
      backgroundColor: "#04887D",
      color: "#ffffff",
      borderColor: "#04887D",
      marginLeft: "132px"
    },

  }
  const data = [
    {
      title: "快递小哥张瑞风雨无阻奔忙派送的一天",
      desc: "7月7日，小暑，连日阴雨的昆明终于放晴。跟平常的每一天一样，早晨8时，快递小哥张瑞准时到达位于羊仙坡的中通快递货场。拉上分拣好的快递，迎着朝阳，穿街过巷，前往自己负责的圆通街片区派件。",
      content: "7月7日，小暑，连日阴雨的昆明终于放晴。跟平常的每一天一样，早晨8时，快递小哥张瑞准时到达位于羊仙坡的中通快递货场。拉上分拣好的快递，迎着朝阳，穿街过巷，前往自己负责的圆通街片区派件。\n" +
        "20岁的张瑞来自曲靖，中等身材，皮肤黑里透红，戴着一副近视眼镜，笑起来很阳光。虽然入行不到半年，他对这个职业的辛苦已深有体会。\n" +
        "“这两天快递不多，今早送了80多件。”因为天气给力，7日早晨的派件很顺利。10时30分左右，张瑞已送完上午时段的包裹返回位于华山街道北仓坡的中通公司门店。“最近雨水多，快递不好送……快递员最怕下雨了。”这位小哥感慨地说。\n" +
        "每天的派件工作分三段，早上8时、中午12时和下午3时，分三次拿货。张瑞回想起刚开始送快递时，因为不熟悉路线、缺乏经验，早上8时段的活要送到中午1时才能送完。慢慢地，边送边总结经验，他学会了理货、规划路线，掌握了一些提高派件效率的技巧，送得越来越得心应手。\n" +
        "“送快递首要的是细心，否则，容易送错或弄丢包裹……”刚开始派件的头两个月，晚上10时多还有客户打电话来找快递。有一次张瑞明明把快递送到了，结果客户没找到，后来才发现是自己把门牌号搞错了，北仓坡22号送到了24号。“不细心的结果，可能就是被投诉、造成损失。”送快递还要有体力。整理包裹、搬运货物，一天来来回回骑着电动车跑很多趟，这些都是体力活。虽然现在的包裹大多是放门卫、寄存柜里，但如果客户要求送货到家都必须满足，有些大件要爬楼扛上去，没点体力是干不了的。\n" +
        "送快递的生活充满酸甜苦辣。没有休息日，顶风冒雨都要派送，自己负责的片区当天必须送完。有时还在理件，客户催单的电话就来了。有些客户接了电话说马上来取件，结果站在小区门口左等右等都不来。有些客户地址没写清，打电话不接，送不出去的件只好又拉回。张瑞说，他接触到的客户大多都挺友好的，接过快递会说声“谢谢”，就是这简简单单的两个字，让疲惫化为暖意。\n" +
        "张瑞的微信签名是：过了年少无知的年纪，该自己努力养活自己。送一份快递只有7毛钱，每天派送200来件，风里来雨里去，一月下来收入仅有3000多元。快递员是一份最普通又特别需要吃苦耐劳的职业，虽然每天风尘仆仆、忙忙碌碌，但年轻的张瑞脸上常常挂着乐观的笑容。\n" +
        "因为父母也来到了昆明，华灯初上，辛苦送完快递，回到温暖小家，吃上热乎乎的晚饭，是奔波一天之后张瑞的幸福时刻。"
    },
    {
      title: "快递员 “快递，就要第一时间送达”",
      desc: "内容：7月29日上午7点半，九宫庙街道百花村卢山锦程顺丰速运营业点的货物分拣库里一片繁忙，快递员们忙着将货物分区分拣，32岁的唐启前就是其中一员。",
      content: "唐启前分拣快递\n" +
        "当天室外温度40℃\n" +
        "每天工作12个小时以上\n" +
        "每天派送100多件快递运单\n" +
        "日行4万余步\n" +
        "炎炎夏日，当我们坐在空调房里轻点鼠标进行网络购物的时候，他们却每天穿行在大街小巷，将一份份包裹、邮件安全地送到市民手中，加上收发件数量的上升，快递员面临着双重“烤验”。\n" +
        "\n" +
        "7月29日上午7点半，九宫庙街道百花村卢山锦程顺丰速运营业点的货物分拣库里一片繁忙，快递员们忙着将货物分区分拣，32岁的唐启前就是其中一员。\n" +
        "\n" +
        "唐启前从事快递员工作已有7年了，无论是高温酷暑还是狂风暴雨，都争取第一时间把快递送到每个客户手里。这么多年，唐启前做到了“零投诉”，这在快递服务行业是很难的，这也让他颇为自豪。\n" +
        "\n" +
        "上午10点15分，烈日当头，室外气温蹿升至40℃。刚刚分拣结束的唐启前来不及休息，便开着派件车出发了。此时，派件车的座椅早已被晒得滚烫，即使行驶起来有风灌入，却没有一丝凉意。\n" +
        "\n" +
        "唐启前来到九宫庙街道思源路路段，把车子停靠在路边，烈日悬在头顶，让人眼睛都睁不开。唐启前蹲下身子，把相应的快递一个个拿出来，一边拨打收件人电话，一边快速分发包裹。“派送快递时，经常会遇到各种问题，比如电话号码是空号、长时间不接听、姓名乱写等，这都会增加派送难度。有时候，电话里答应马上取件，可取件人一直不出现，我们在室外等大半个小时是常有的事。”唐启前无奈地说。\n" +
        "\n" +
        "每次派件时，小区保安签收室里的空调成了唐启前短暂的奢望，每次到签收室让保安代收快递时，他总想在那里多待一会，可是为了赶时间，又不得不离开。唐启前告诉记者，不管什么天气，他一天要工作12个小时以上，送近百个包裹，遇到出行不便的居民，他还要送货上门。\n" +
        "\n" +
        "记者跟随唐启前来到天安珑园派送大米。唐启前打完电话后却没有让对方下楼取件，而是扛起两袋米就直径走进电梯。“这位独居老人70多岁了，腿脚不利索，她喜欢电视购物，我每次都将包裹直接送上楼，这样她收货比较方便。”唐启前告诉记者。\n" +
        "\n" +
        "“快递行业讲究的就是‘快’，要求速度快、效率高，所以无论如何我都必须把包裹在当天派送完。”唐启前告诉记者，有时渴了累了，他就买瓶矿泉水解解暑，然后继续踏上送货的路程。对他来说，送到的不仅是邮件、包裹，更是一份责任。\n" +
        "\n" +
        "晚上19点，唐启前当天的140多个快递全部送完，行驶了40多公里，步行了4万多步。“一句感谢，一声叮嘱，一瓶冰水，一袭凉风，都让我觉得高温酷暑不那么难耐。”唐启前告诉记者，工作中经常会收获到暖暖的关心。尤其是进入高温天气以后，客户们都非常理解他们的辛苦，“还会特意叮嘱不用赶在正午派送快递，宁愿自己多等一会儿。”"
    }
  ]
  return (<div style={styles.bg}>
      <Column>
        <div className={"TitleLabel"}>快递新闻</div>
        {
          data.map(((value, index) => {
            return <Container
              key={index}
              decoration={{
                stroke: {color: "#09665E", width: "2px"},
                borderRadius: Radius.all("8px")
              }}
              padding={EdgeInsets.all("16px")}
              margin={EdgeInsets.symmetric("148px", "19px")}>
              <Row>
                <Column>
                  <div style={styles.title}>{value.title}</div>
                  <div className={"news-desc"}>{value.desc}</div>
                </Column>
                <Button style={styles.btn} onClick={() => {
                  history.push(
                    "news/detail",
                    {
                      text: value.content
                    }
                  )
                }}>查看</Button>
              </Row>
            </Container>
          }))
        }
      </Column>
    </div>
  )
}
export default NewsPage