import React from "react";
import Row from "../../widget/common/linear/row";
import Column from "../../widget/common/linear/column";
import Container from "../../widget/container";
import zhongtong from "../../img/small/zhongtong123.jpeg"
import yunda from "../../img/small/yunda123.jpg"
import shentong from "../../img/small/shentong123.png"
import yuantong from "../../img/small/yuantong123.png"
import baishi from "../../img/small/baishi123.png"
import youzheng from "../../img/small/youzheng.png"
import jitu from "../../img/small/jitu.png"
import courseImg from "../../img/course_img.webp"
import SizeBox from "../../widget/common/size_box";
import {Button} from "antd";
import {Radius} from "../../widget/common/box_decoration";
import {EdgeInsets} from "../../widget/common/edgeInsets";
import './course_page.css'
import Expand from "../../widget/common/linear/expand";

const {useHistory} = require('react-router-dom');

const CoursePage: React.FC = () => {
  let history = useHistory();//全局的总页面，切换整个页面
  const styles = {
    bg: {
      maxWidth: "1920px",
      margin: "0 auto",
      height: "100%",
      overflow:"scroll"
    },
    img: {
      width: "60px",
    },
    img_right: {
      width: "222px",
      marginLeft: "160px"
    },
    title: {
      fontSize: "20px",
      color: "#ffffff"
    },
    desc: {
      fontSize: "14px",
      color: "#F0F0F0"
    },
    btn: {
      backgroundColor: "#04887D",
      color: "#ffffff",
      borderColor: "#04887D",
      marginLeft: "132px"
    },

  }
  const data = [
    {title: "中通快递", "img": zhongtong},
    {title: "韵达快递", "img": yunda},
    {title: "申通快递", "img": shentong},
    {title: "圆通快递", "img": yuantong},
    {title: "百世快递", "img": baishi},
    {title: "中通快递", "img": youzheng},
    {title: "极兔快递", "img": jitu},
  ]
  return (<div style={styles.bg}>
      <Row justifyContent={"left"}>
        <Column>
          <div className={"TitleLabel"}>各快递教程</div>
          {
            data.map(((value, index) => {
              return <Container
                key={index}
                decoration={{
                  stroke: {color: "#09665E", width: "2px"},
                  borderRadius: Radius.all("8px")
                }}
                padding={EdgeInsets.all("16px")}
                margin={EdgeInsets.symmetric("148px", "19px")}>
                <Row>
                  <img style={styles.img} src={value.img} alt={""}/>
                  <SizeBox width={"28px"}/>
                  <Column>
                    <div style={styles.title}>{value.title}，快递批量查询教程</div>
                    <div style={styles.desc}>查快递，查物流，{value.title}寄件查询查看教程</div>
                  </Column>
                  <Button style={styles.btn} onClick={() => {
                    history.push("/detail")
                  }}>查看</Button>
                </Row>
              </Container>
            }))
          }
        </Column>
        <img style={styles.img_right} src={courseImg} alt={""}/>
      </Row>
    </div>
  )
}
export default CoursePage