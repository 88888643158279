import React, {FC, Fragment} from "react";

type AlignmentParam = {
  left?: string | null,
  top?: string | null,
  right?: string | null,
  bottom?: string | null,
}

/**
 * 只在Stack内部使用，控制他的位置
 * @param left
 * @param top
 * @param right
 * @param bottom
 * @param children
 * @constructor
 */
const Position: FC<AlignmentParam> = (
  {
    left = null,
    top = null,
    right = null,
    bottom = null,
    children
  }) => {
  // @ts-ignore
  if ("props" in children) {
    if (left == null && bottom == null && top == null && right == null) {
      top = "0";
    }
    const style = {
      left: left,
      top: top,
      right: right,
      bottom: bottom,
      margin: "auto",
      position: "absolute",
    }
    const newChild = React.cloneElement(
      children,
      {style: style}
    )
    return <Fragment>
      {newChild}
    </Fragment>
  }
  return <Fragment>
    {children}
  </Fragment>
}
export default Position