export type BoxDecoration = {
  color?: string,
  borderRadius?: BorderRadiusGeometry,
  stroke?: StrokeGeometry
}
export type BorderRadiusGeometry = {
  topLeft?: string,
  topRight?: string,
  bottomLeft?: string,
  bottomRight?: string,
}
export type StrokeGeometry = {
  color?: string,
  width?: string
}
export const Radius = {
  only(edgeInsetsGeometry: BorderRadiusGeometry): BorderRadiusGeometry {
    return edgeInsetsGeometry
  },
  all(value: string): BorderRadiusGeometry {
    return {
      topLeft: value,
      topRight: value,
      bottomLeft: value,
      bottomRight: value,
    }
  }
}