import React, {FC, Fragment} from "react";

type ExpandParam = {
  flex: number,
}
/**
 * 仅在Row和Column内部使用
 * 在无其他css影响下。默认内部居中
 * @param flex 权重
 * @param children
 * @constructor
 */
const Expand: FC<ExpandParam> = ({flex, children}) => {
  // @ts-ignore
  if ("props" in children) {
    const style =
      {
        flex: (flex ?? 1),
      }
    const newChild = React.cloneElement(
      children,
      {style}
    )
    return <Fragment>
      {newChild}
    </Fragment>
  }
  return <Fragment>
    {children}
  </Fragment>
}
export default Expand