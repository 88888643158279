import React from "react";
import Row from "../../widget/common/linear/row";
import Column from "../../widget/common/linear/column";
import Container from "../../widget/container";
import {Radius} from "../../widget/common/box_decoration";
import {EdgeInsets} from "../../widget/common/edgeInsets";
import logo from "../../img/logo.webp"
import Expand from "../../widget/common/linear/expand";
import './function_page.css'
const FunctionPage: React.FC = () => {
  const styles = {
    bg: {
      maxWidth: "1920px",
      margin: "0 auto",
    },
    logo: {
      height: "39px",
      marginBottom:"10px"
    },
    desc: {
      fontSize: "24px",
      color: "#04887D",
      marginLeft: "148px",
      marginTop: "20px"
    },
    cardTitle: {
      fontSize: "38px",
      color: "#C2C2C2",
      fontWeight: 900
    },
  }
  return (<div style={styles.bg}>
    <Column height={"100%"}>
      <div className={"TitleLabel"}>快递批量查询</div>
      <Row justifyContent={"left"}>
        <Column>
          <div style={styles.desc}>您是否还在被动的等客户催件？</div>
          <div style={styles.desc}>您是否还在一件件跟单？</div>
          <div style={styles.desc}>您还在纠结是否需要补发快递？</div>
          <div style={styles.desc}>您是否知道对方派件延误了？</div>
        </Column>
        <Expand flex={1}>
          <div/>
        </Expand>
        <Container
          decoration={{
            color: "#2b2b2b",
            borderRadius: Radius.all("8px")
          }}
          margin={EdgeInsets.horizontal("177px")}
          padding={EdgeInsets.symmetric("46px", "40px")}>
          <Column>
            <img style={styles.logo} src={logo}  alt={""}/>
            <div className={"FunctionCardDesc"}>快递批量查询神器，一键导入快递单号，批量查询</div>
            <div className={"FunctionCardDesc"}>支持所有快递公司批量查询快递动态</div>
            <div className={"FunctionCardDesc"}>韵达、中通、极兔、申通、圆通、邮政、百世、顺丰、京东等各大快递都支持</div>
            <div className={"FunctionCardDesc"}>签收、在途、无物流、异常件，分门别类一目了然，可导出成Excel筛选进一步分析数据</div>
          </Column>
        </Container>
      </Row>
    </Column>
  </div>)
}
export default FunctionPage