import {FC} from "react";
import {Property} from "csstype";

type RowParam = {
  height?: string,
  width?: string,
  backgroundColor?: string,
  justifyContent?: Property.JustifyContent,
}
const Row: FC<RowParam> = (
  {
    height,
    width,
    backgroundColor,
    justifyContent,
    children
  }) => {
  return (
    <div
      style={{
        height: (height ?? "undefined"),
        width: (width ?? "undefined"),
        display: "flex",
        flexDirection: "row",
        justifyContent: (justifyContent ?? "center"),
        alignItems: "center",
        backgroundColor: backgroundColor ?? "undefined"
      }}>
      {children}{/*获取子view*/}
    </div>)
}
export default Row

