import logo from "../../img/logo.webp"
import './main_page.css'
import Row from "../../widget/common/linear/row";
import {Layout, Menu} from 'antd';
import Home from "../home/home_page";
import FooterView from "../../view/footer_view";
import QueryPage from "../query/query_page";
import FunctionPage from "../function/function_page";
import PricePage from "../price_page/price_page";
import CoursePage from "../course/course_page";
import AboutPage from "../about/about_page";
import DetailPage from "../detail/detail_page";
import React, {useEffect, useState} from "react";
import NewsPage from "../news/news_page";
import NewsDetailPage from "../news/news_detail_page";

const {Header, Footer, Content} = Layout;
const {Switch, Route} = require('react-router-dom');
const {useHistory} = require('react-router-dom');

const Main: React.FC = () => {
  let history = useHistory();//全局的总页面，切换整个页面
  const [tab, setTab] = useState('0')

  let menu = [
    {desc: "首页", router: "/"},
    {desc: "批量查询快递", router: "/query"},
    {desc: "功能", router: "/function"},
    {desc: "价格", router: "/price"},
    {desc: "各快递教程", router: "/course"},
    {desc: "快递新闻", router: "/news"},
    {desc: "关于快递云", router: "/about"}]

  let menuClick = (e: any) => {
    // @ts-ignore
   // _czc && _czc.push(["_trackEvent", "页面", "跳转", "页面名称", menu[e.key].desc, menu[e.key].desc])
    history.replace(menu[e.key].router)
  };
  useEffect(() => {
    let path = history.location.pathname
    switch (path) {
      case "/":
        setTab('0')
        break
      case "/query":
        setTab('1')
        break
      case "/function":
        setTab('2')
        break
      case "/price":
        setTab('3')
        break
      case "/course":
      case "/detail":
        setTab('4')
        break
      case "/news":
      case "/news/detail":
        setTab('5')
        break
      case "/about":
        setTab('6')
        break
    }
  },)

  //BrowserRouter全局只有1个,二级页面直接<Switch><Route>...
  return (<Layout className={"Layout"}>
    <Header className={"HeadLayout"}>
      <Row height={"60px"}
           justifyContent={"left"}>
        <img className={"Logo"} src={logo} alt={""}/>
        <Menu className={"Menu"} mode="horizontal" selectedKeys={[tab]} expandIcon={true}>
          {menu.map((value, index) => {
            return <Menu.Item
              className={"Item"} key={index}
              onClick={menuClick}>{value.desc}
            </Menu.Item>
          })}
        </Menu>
      </Row>
    </Header>
    <Content className={"ContentLayout"}>
      <Switch>
        <Route exact path='/' component={Home}/>
        <Route exact path='/query' component={QueryPage}/>
        <Route exact path='/function' component={FunctionPage}/>
        <Route exact path='/price' component={PricePage}/>
        <Route exact path='/course' component={CoursePage}/>
        <Route exact path='/news' component={NewsPage}/>
        <Route exact path='/news/detail' component={NewsDetailPage}/>
        <Route exact path='/about' component={AboutPage}/>
        <Route exact path='/detail' component={DetailPage}/>
      </Switch>
    </Content>
    <Footer className={"BottomLayout"}>
      <FooterView/>
    </Footer>
  </Layout>)
}
export default Main