import {FC} from "react";
import {EdgeInsetsGeometry} from "./common/edgeInsets";
import {BoxDecoration} from "./common/box_decoration";

type ContainerParam = {
  padding?: EdgeInsetsGeometry
  margin?: EdgeInsetsGeometry,
  decoration?: BoxDecoration
}
/**
 * 一个简单的容器
 * @param padding
 * @param margin
 * @param decoration
 * @param children
 * @constructor
 */
const Container: FC<ContainerParam> = (
  {
    padding,
    margin,
    decoration,
    children
  }) => {
  return <div style={{
    paddingLeft: padding?.left ?? "undefined",
    paddingTop: padding?.top ?? "undefined",
    paddingRight: padding?.right ?? "undefined",
    paddingBottom: padding?.bottom ?? "undefined",
    marginLeft: margin?.left ?? "undefined",
    marginTop: margin?.top ?? "undefined",
    marginRight: margin?.right ?? "undefined",
    marginBottom: margin?.bottom ?? "undefined",
    backgroundColor: decoration?.color ?? "undefined",
    borderTopLeftRadius: decoration?.borderRadius?.topLeft ?? "undefined",
    borderTopRightRadius: decoration?.borderRadius?.topRight ?? "undefined",
    borderBottomLeftRadius: decoration?.borderRadius?.bottomLeft ?? "undefined",
    borderBottomRightRadius: decoration?.borderRadius?.bottomRight ?? "undefined",
    border: decoration?.stroke?.width ? (decoration?.stroke?.width + " solid " + decoration?.stroke?.color) : "undefined",
    borderColor: decoration?.stroke?.color ?? "undefined"
  }}>
    {children}
  </div>
}
export default Container